import React from 'react';
import './App.css';
import UserList from './containers/UserList';

function App() {

  return (
    <UserList/>
  )
}

export default App;
